const appConfig = {
    Telemetry: {
        ServiceOffering: 'Finance',
        ServiceLine: 'Global Trade',
        Service: 'Global Trade Reporting',
        ComponentName: 'India Imports',
        ComponentId: 'a4ee6ba4-4a3f-431e-89cd-72053d22e9b4',
        IctoId: 'ICTO-4855-Global Trade Reporting',
        CorrelationKey: '',
        EnvironmentName: '__EnvironmentName__',
        InstrumentationKey: {
            development: '__InstrumentationKeyUI__',
            production: '__InstrumentationKeyUI__',
            test: '__InstrumentationKeyUI__',
            none: '',
        },
        TraceEnabled: true,
        ItExtVersion: 'npm:2.5.4-build0610',
    },
    AppSettings: {
        APIUrl: '__APIUrl__',
        UIUrl: '__BaseUrl__',
        AppClientId: '__AppClientId__',
        APIMKey : '__APIMSubscriptionKey__'
    },
    Search: {
        ResultsPerPage: 20,
        NoOfCategories: 5,
        NoOfOptions: 4,
        EntitiesPerPage: 10,
    },
    Feedback: {
        development: {
            EnableLineItemFeedback: true,
            EnablePageFeedback: true,
            EnablePageFeedbackButton: false,
        },
        production: {
            EnableLineItemFeedback: true,
            EnablePageFeedback: true,
            EnablePageFeedbackButton: false,
        },
        test: {
            EnableLineItemFeedback: true,
            EnablePageFeedback: true,
            EnablePageFeedbackButton: false,
        },
        none: {
            EnableLineItemFeedback: true,
            EnablePageFeedback: true,
            EnablePageFeedbackButton: false,
        },
    },
    StateManager: {
        MaxHistory: 3,
    },
};

module.exports = {
    appConfig,
};
