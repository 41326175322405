var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import * as Styled from '../../../shared/layout';
import { Fabric, DetailsListLayoutMode, SelectionMode } from 'office-ui-fabric-react';
import { useState, useEffect } from 'react';
import { ComponentContext } from '@employee-experience/common/lib/Context/ComponentContext';
import { toast } from 'react-toastify';
import { EventEmitter, EventType } from '../../CommonUtilities/EventEmitter';
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
import { LoadingIndicator } from '../../CommonUtilities/LoadingIndicator';
import { appConfig } from '../../../config/appConfig';
import EditableGridWithPagination from '../EditableGridWithPagination';
import { DetailReportColumnConfigurationData } from '../EditableGridConfigReportPages';
import { ExportToCSVUtil, ExportToExcelUtil } from '../../CommonUtilities/ExcelExport';
import { ExportType } from '../../CommonUtilities/editable-grid/EditableGridConfig';
import { httpGet } from '../../../shared/APICalls';
var baseUrl = appConfig.AppSettings.APIUrl;
function DetailedReport(props) {
    var _this = this;
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _a = useState([]), defaultItems = _a[0], setDefaultItems = _a[1];
    var _b = useState([]), notifications = _b[0], setNotifications = _b[1];
    var onSuccessCallback = function (response) {
        setDefaultItems(response.data);
        if (response) {
            if (response.data.length == 0)
                toast.success("No entries to show.");
        }
    };
    var onErrorCallback = function (error) {
        toast.error('Invalid request or server error. Please try again later.');
    };
    var FetchNotificationDropdownListDetails = function () { return __awaiter(_this, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = baseUrl + "/ConfigureRates/NotificationRates";
            httpGet(_httpClient, url, function (response) {
                var notifData = response.data ? response.data : [];
                setNotifications(notifData.map(function (name) { return ({
                    key: name.id,
                    text: name.notification_Number + '-' + name.serial_Number,
                }); }));
            }, onErrorCallback);
            return [2 /*return*/];
        });
    }); };
    var onClickGetDataHandler = function (event) {
        var searchId = event.target.value;
        var url = baseUrl + "/Reports/Details?searchId=" + searchId;
        httpGet(_httpClient, url, onSuccessCallback, onErrorCallback);
    };
    var ConstructExportData = function () {
        var data = [];
        defaultItems.forEach(function (item, index) {
            var dataRow = {};
            DetailReportColumnConfigurationData.forEach(function (element) {
                dataRow[element.text] = item[element.key];
            });
            data.push(dataRow);
        });
        return data;
    };
    var onDetailedExcelExport = function (exportType) {
        var data = ConstructExportData();
        switch (exportType) {
            case ExportType.XLSX:
                ExportToExcelUtil(data, 'DetailedReport.xlsx');
                break;
            case ExportType.CSV:
                ExportToCSVUtil(data, 'DetailedReport.csv');
                break;
        }
    };
    useEffect(function () {
        var url = baseUrl + "/Reports/Details?fromDate=" + props.gridFilteringData['fromDate'] + "&toDate=" + props.gridFilteringData['toDate'] + "&companyCode=" + props.gridFilteringData['companyCode'] + "&shipmentStatus=" + props.gridFilteringData['shipmentStatus'] + "&filterType=" + props.gridFilteringData['filterType'];
        httpGet(_httpClient, url, onSuccessCallback, onErrorCallback);
        FetchNotificationDropdownListDetails();
    }, [props.gridFilteringData]);
    useEffect(function () {
        EventEmitter.subscribe(EventType.onClick, onClickGetDataHandler);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onClick, onClickGetDataHandler);
        };
    });
    useEffect(function () {
        toast.configure({
            position: 'top-center',
            autoClose: 5000,
            bodyClassName: 'toast-body',
            hideProgressBar: true,
            style: { marginTop: 50 },
        });
    }, []);
    useEffect(function () {
        defaultItems.forEach(function (defaultItem) {
            if (defaultItem.notifBasicCustomID) {
                defaultItem.notifBasicCustomID = notifications.filter(function (item) { return item.key == defaultItem.notifBasicCustomID; })[0].text;
            }
            if (defaultItem.notifIGSTID) {
                defaultItem.notifIGSTID = notifications.filter(function (item) { return item.key == defaultItem.notifIGSTID; })[0].text;
            }
        });
    }, [notifications, defaultItems]);
    return (React.createElement(Styled.listContainer, null,
        React.createElement(Fabric, null,
            React.createElement("div", null,
                React.createElement(LoadingIndicator, null)),
            defaultItems.length > 0 && (React.createElement(EditableGridWithPagination, { enableColumnEdit: false, enableExport: true, enableSave: false, columnConfigurationData: DetailReportColumnConfigurationData, layoutMode: DetailsListLayoutMode.justified, selectionMode: SelectionMode.none, enableRowEdit: false, enableBulkEdit: false, gridData: defaultItems, onExcelExport: onDetailedExcelExport })))));
}
export default DetailedReport;
