var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CommandBarButton, DayOfWeek, DetailsListLayoutMode, Fabric, FontWeights, IconButton, Modal, SelectionMode, Stack, getTheme, mergeStyleSets, } from '@fluentui/react';
import * as Styled from '../../shared/layout';
import { useId, useBoolean } from '@fluentui/react-hooks';
import * as React from 'react';
import { LoadingIndicator } from '../CommonUtilities/LoadingIndicator';
import { ComponentContext } from '@employee-experience/common';
import { useEffect, useState } from 'react';
import EditableGridWithPagination from '../Reports/EditableGridWithPagination';
import { ExchangeRateColumnConfigurationData } from './EditableGridConfigExchangeRate';
import { appConfig } from '../../config/appConfig';
import { httpGet, httpPost } from '../../shared/APICalls';
import { toast } from 'react-toastify';
import { TextField, PrimaryButton } from '@fluentui/react';
import { DatePicker } from 'office-ui-fabric-react';
var baseUrl = appConfig.AppSettings.APIUrl;
function ConfigureExchangeRate() {
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _a = useState([]), defaultItems = _a[0], setDefaultItems = _a[1];
    var datePickerStrings = {
        months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        goToToday: 'Go to today',
        prevMonthAriaLabel: 'Go to previous month',
        nextMonthAriaLabel: 'Go to next month',
        prevYearAriaLabel: 'Go to previous year',
        nextYearAriaLabel: 'Go to next year',
        closeButtonAriaLabel: 'Close date picker',
        isRequiredErrorMessage: 'Field is required.',
    };
    var today = new Date();
    var _b = useState({
        fromDate: today,
        toDate: today,
        fromCurrency: '',
        toCurrency: '',
        exchangeRate: '0',
        method: '',
        id: 0,
    }), formData = _b[0], setFormData = _b[1];
    var handleChange = function (event, fieldName) {
        var _a;
        setFormData(__assign(__assign({}, formData), (_a = {}, _a[fieldName] = event.target.value, _a)));
    };
    var handleFromDateChange = function (date) {
        setFormData(__assign(__assign({}, formData), { fromDate: date || today }));
    };
    var onPostShowModal = function () {
        setFormData({
            fromDate: today,
            toDate: today,
            fromCurrency: 'USD',
            toCurrency: 'INR',
            exchangeRate: '0',
            method: 'Post',
            id: 0,
        });
        showModal();
    };
    var handleToDateChange = function (date) {
        setFormData(__assign(__assign({}, formData), { toDate: date || today }));
    };
    var handleSubmit = function (event) {
        event.preventDefault();
        hideModal();
        var url = baseUrl + "/ConfigureRates/UpdateExchangeRates";
        var createRequestObject = {
            FDate: formatDate(formData.fromDate),
            EDate: formatDate(formData.toDate),
            From_Currency: formData.fromCurrency,
            To_Currency: formData.toCurrency,
            Exchange_Rate: formData.exchangeRate,
            method: formData.method,
            id: formData.id,
        };
        if (formData.fromDate > formData.toDate) {
            toast.error("From Date cannot be greater than To Date.");
            return;
        }
        if (formData.exchangeRate == null || formData.exchangeRate == '' || parseFloat(formData.exchangeRate) < 0) {
            toast.error("Please Provide valid Exchange Rate");
            return;
        }
        httpPost(_httpClient, url, onPOSTRequestSuccessCallback, onErrorCallback, [createRequestObject]);
    };
    var formatDate = function (date) {
        if (!date)
            return new Date().toISOString();
        var newDate = "" + date.getDate();
        var newMonth = "" + (date.getMonth() + 1);
        if (date.getMonth() < 10) {
            newMonth = "0" + (date.getMonth() + 1);
        }
        if (date.getDate() < 10) {
            newDate = "0" + date.getDate();
        }
        return date.getFullYear() + "-" + newMonth + "-" + newDate + "T00:00:00.000Z";
    };
    var onPOSTRequestSuccessCallback = function (response) {
        if (response.data.status == false) {
            toast.error(response.data.message);
        }
        if (response.data.status == true) {
            toast.success(response.data.message);
        }
        var url = baseUrl + "/ConfigureRates/ExchangeRates";
        httpGet(_httpClient, url, onSuccessCallback, onErrorCallback);
    };
    var onSuccessCallback = function (response) {
        setDefaultItems(response.data);
        if (response) {
            if (response.data.length == 0)
                toast.success("No entries to show.");
        }
    };
    var onErrorCallback = function (error) {
        toast.error('Invalid request or server error. Please try again later.');
    };
    useEffect(function () {
        var url = baseUrl + "/ConfigureRates/ExchangeRates";
        httpGet(_httpClient, url, onSuccessCallback, onErrorCallback);
    }, []);
    var addIcon = { iconName: 'Add' };
    var stackStyles = {
        root: {
            height: 44,
            marginTop: 8,
            marginBottom: 5,
            marginLeft: 10,
        },
    };
    useEffect(function () {
        toast.configure({
            position: 'top-center',
            autoClose: 5000,
            bodyClassName: 'toast-body',
            hideProgressBar: true,
            style: { marginTop: 50 },
        });
        setDefaultItems(defaultItems);
    }, [defaultItems]);
    var verticalGapStackTokens = {
        childrenGap: 15,
        padding: 10,
    };
    var theme = getTheme();
    var _c = useBoolean(false), isModalOpen = _c[0], _d = _c[1], showModal = _d.setTrue, hideModal = _d.setFalse;
    var titleId = useId('Add Exchange Rate Data');
    var contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            // eslint-disable-next-line deprecation/deprecation
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: "4px solid " + theme.palette.themePrimary,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        heading: {
            color: theme.palette.neutralPrimary,
            fontWeight: FontWeights.semibold,
            fontSize: 'inherit',
            margin: '0',
        },
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    var cancelIcon = { iconName: 'Cancel' };
    var iconButtonStyles = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };
    var onRowEdit = function (rowData) {
        setFormData({
            fromDate: new Date(rowData[0].fDate),
            toDate: new Date(rowData[0].eDate),
            fromCurrency: rowData[0].from_Currency,
            toCurrency: rowData[0].to_Currency,
            exchangeRate: rowData[0].exchange_Rate,
            method: 'Put',
            id: rowData[0].id,
        });
        showModal();
    };
    return (React.createElement("div", null,
        React.createElement(LoadingIndicator, null),
        React.createElement(Stack, { horizontal: true, styles: stackStyles },
            React.createElement(CommandBarButton, { onClick: onPostShowModal, iconProps: addIcon, text: "Add Exchange Rate Data" })),
        React.createElement(Modal, { titleAriaId: titleId, isOpen: isModalOpen, onDismiss: hideModal, isBlocking: false, containerClassName: contentStyles.container },
            React.createElement("div", { className: contentStyles.header },
                React.createElement("h2", { className: contentStyles.heading, id: titleId }, "Add Exchange Rate Data"),
                React.createElement(IconButton, { styles: iconButtonStyles, iconProps: cancelIcon, ariaLabel: "Close popup modal", onClick: hideModal })),
            React.createElement("div", { className: contentStyles.body },
                React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(Stack, { tokens: verticalGapStackTokens },
                        React.createElement(DatePicker, { label: "From Date", value: formData.fromDate, placeholder: "Select a date", showMonthPickerAsOverlay: true, formatDate: function (date) { return date.toLocaleDateString(); }, firstDayOfWeek: DayOfWeek.Sunday, strings: datePickerStrings, onSelectDate: handleFromDateChange }),
                        React.createElement(DatePicker, { label: "To Date", value: formData.toDate, placeholder: "Select a date", showMonthPickerAsOverlay: true, formatDate: function (date) { return date.toLocaleDateString(); }, firstDayOfWeek: DayOfWeek.Sunday, strings: datePickerStrings, onSelectDate: handleToDateChange }),
                        React.createElement(TextField, { label: "Exchange Rate", value: formData.exchangeRate, onChange: function (event, value) { return handleChange(event, 'exchangeRate'); } }),
                        React.createElement(PrimaryButton, { type: "submit", text: "Submit" }))))),
        React.createElement(Styled.listContainer, null,
            React.createElement(Fabric, null, defaultItems.length > 0 && (React.createElement(EditableGridWithPagination, { enableColumnEdit: false, enableExport: true, enableSave: false, columnConfigurationData: ExchangeRateColumnConfigurationData, layoutMode: DetailsListLayoutMode.justified, selectionMode: SelectionMode.single, enableRowEdit: true, enableBulkEdit: false, gridData: defaultItems, onRowEdit: onRowEdit }))))));
}
export { ConfigureExchangeRate as ConfigureExchangeRate };
