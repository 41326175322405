var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CommandBarButton, DetailsListLayoutMode, Fabric, FontWeights, IconButton, Modal, PrimaryButton, SelectionMode, Stack, TextField, getTheme, makeStyles, mergeStyleSets, } from '@fluentui/react';
import * as Styled from '../../shared/layout';
import * as React from 'react';
import { LoadingIndicator } from '../CommonUtilities/LoadingIndicator';
import { ComponentContext } from '@employee-experience/common';
import { useEffect, useState } from 'react';
import EditableGridWithPagination from '../Reports/EditableGridWithPagination';
import { DutyRateColumnConfigurationData } from './EditableGridConfigExchangeRate';
import { appConfig } from '../../config/appConfig';
import { httpGet, httpPost } from '../../shared/APICalls';
import { toast } from 'react-toastify';
import { useId, useBoolean } from '@fluentui/react-hooks';
var baseUrl = appConfig.AppSettings.APIUrl;
var useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
    },
});
function ConfigureDutyRate() {
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _a = useState([]), defaultItems = _a[0], setDefaultItems = _a[1];
    var onSuccessCallback = function (response) {
        setDefaultItems(response.data);
        if (response) {
            if (response.data.length == 0)
                toast.success("No entries to show.");
        }
    };
    var _b = useState({
        companyCode: '',
        hsnCode: '',
        igstRate: '0',
        basicCustomsDutyRate: '0',
        ecess: '0',
        splDuty: '0',
        cessRate: '0',
        method: '',
    }), formData = _b[0], setFormData = _b[1];
    var onErrorCallback = function (error) {
        toast.error('Invalid request or server error. Please try again later.');
    };
    var addIcon = { iconName: 'Add' };
    var stackStyles = {
        root: {
            height: 44,
            marginTop: 8,
            marginBottom: 5,
            marginLeft: 10,
        },
    };
    var onPOSTRequestSuccessCallback = function (response) {
        if (response.data.status == false) {
            toast.error(response.data.message);
        }
        if (response.data.status == true) {
            toast.success(response.data.message);
        }
        var url = baseUrl + "/ConfigureRates/DutyRates";
        httpGet(_httpClient, url, onSuccessCallback, onErrorCallback);
    };
    var handleChange = function (event, fieldName) {
        var _a;
        setFormData(__assign(__assign({}, formData), (_a = {}, _a[fieldName] = event.target.value, _a)));
    };
    var handleSubmit = function (event) {
        event.preventDefault();
        hideModal();
        var url = baseUrl + "/ConfigureRates/UpdateDutyRates";
        var createRequestObject = {
            Company_Code: formData.companyCode,
            HSN_Code: formData.hsnCode,
            IGST_Rate: formData.igstRate || '0',
            Basic_Customs_Duty_Rate: formData.basicCustomsDutyRate || '0',
            Ecess: formData.ecess || '0',
            Spl_Duty: formData.splDuty || '0',
            Cess_Rate: formData.cessRate || '0',
            method: formData.method,
        };
        if (formData.companyCode.length !== 4) {
            toast.error('Company Code should be of 4 characters.');
            return;
        }
        if (formData.hsnCode == '' || formData.hsnCode == null) {
            toast.error('HSN Code is mandatory.');
            return;
        }
        if (formData.companyCode == '' || formData.companyCode == null) {
            toast.error('Company Code is mandatory.');
            return;
        }
        if (parseFloat(formData.igstRate) < 0 ||
            parseFloat(formData.splDuty) < 0 ||
            parseFloat(formData.basicCustomsDutyRate) < 0 ||
            parseFloat(formData.ecess) < 0 ||
            parseFloat(formData.cessRate) < 0) {
            toast.error('Please enter Non Negative values for rates.');
            return;
        }
        httpPost(_httpClient, url, onPOSTRequestSuccessCallback, onErrorCallback, [createRequestObject]);
    };
    useEffect(function () {
        toast.configure({
            position: 'top-center',
            autoClose: 5000,
            bodyClassName: 'toast-body',
            hideProgressBar: true,
            style: { marginTop: 50 },
        });
        var url = baseUrl + "/ConfigureRates/DutyRates";
        httpGet(_httpClient, url, onSuccessCallback, onErrorCallback);
    }, []);
    var theme = getTheme();
    var _c = useBoolean(false), isModalOpen = _c[0], _d = _c[1], showModal = _d.setTrue, hideModal = _d.setFalse;
    var titleId = useId('Add Duty Rate Data');
    var verticalGapStackTokens = {
        childrenGap: 15,
        padding: 10,
    };
    var contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            // eslint-disable-next-line deprecation/deprecation
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: "4px solid " + theme.palette.themePrimary,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        heading: {
            color: theme.palette.neutralPrimary,
            fontWeight: FontWeights.semibold,
            fontSize: 'inherit',
            margin: '0',
        },
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    var cancelIcon = { iconName: 'Cancel' };
    var iconButtonStyles = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };
    var onRowEdit = function (rowData) {
        setFormData({
            companyCode: rowData[0].company_Code,
            hsnCode: rowData[0].hsN_Code,
            igstRate: rowData[0].igsT_Rate,
            basicCustomsDutyRate: rowData[0].basic_Customs_Duty_Rate,
            ecess: rowData[0].ecess,
            splDuty: rowData[0].spl_Duty,
            cessRate: rowData[0].cess_Rate,
            method: 'Put',
        });
        showModal();
    };
    var onPostDutyRate = function () {
        setFormData({
            companyCode: '',
            hsnCode: '',
            igstRate: '0',
            basicCustomsDutyRate: '0',
            ecess: '0',
            splDuty: '0',
            cessRate: '0',
            method: 'Post',
        });
        showModal();
    };
    return (React.createElement("div", null,
        React.createElement(Stack, { horizontal: true, styles: stackStyles },
            React.createElement(CommandBarButton, { onClick: onPostDutyRate, iconProps: addIcon, text: "Add Duty Rate Data" })),
        React.createElement(Modal, { titleAriaId: titleId, isOpen: isModalOpen, onDismiss: hideModal, isBlocking: false, containerClassName: contentStyles.container },
            React.createElement("div", { className: contentStyles.header },
                React.createElement("h2", { className: contentStyles.heading, id: titleId }, "Add Duty Rate Data"),
                React.createElement(IconButton, { styles: iconButtonStyles, iconProps: cancelIcon, ariaLabel: "Close popup modal", onClick: hideModal })),
            React.createElement("div", { className: contentStyles.body },
                React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(Stack, { tokens: verticalGapStackTokens },
                        React.createElement(TextField, { label: "Company Code", value: formData.companyCode, onChange: function (event, value) { return handleChange(event, 'companyCode'); }, disabled: formData.method === 'Put' ? true : false }),
                        React.createElement(TextField, { label: "HSN Code", value: formData.hsnCode, onChange: function (event, value) { return handleChange(event, 'hsnCode'); }, disabled: formData.method === 'Put' ? true : false }),
                        React.createElement(TextField, { label: "IGST Rate", value: formData.igstRate, onChange: function (event, value) { return handleChange(event, 'igstRate'); } }),
                        React.createElement(TextField, { label: "Basic Customs Duty Rate", value: formData.basicCustomsDutyRate, onChange: function (event, value) { return handleChange(event, 'basicCustomsDutyRate'); } }),
                        React.createElement(TextField, { label: "Social Welfare Surcharge Rate", value: formData.ecess, onChange: function (event, value) { return handleChange(event, 'ecess'); } }),
                        React.createElement(TextField, { label: "Spl Duty", value: formData.splDuty, onChange: function (event, value) { return handleChange(event, 'splDuty'); } }),
                        React.createElement(TextField, { label: "Cess Rate", value: formData.cessRate, onChange: function (event, value) { return handleChange(event, 'cessRate'); } }),
                        React.createElement(PrimaryButton, { type: "submit", text: "Submit" }))))),
        React.createElement(LoadingIndicator, null),
        React.createElement(Styled.listContainer, null,
            React.createElement(Fabric, null, defaultItems.length > 0 && (React.createElement(EditableGridWithPagination, { enableColumnEdit: false, enableExport: true, enableSave: false, columnConfigurationData: DutyRateColumnConfigurationData, layoutMode: DetailsListLayoutMode.justified, selectionMode: SelectionMode.none, enableRowEdit: true, enableBulkEdit: false, gridData: defaultItems, onRowEdit: onRowEdit }))))));
}
export { ConfigureDutyRate as ConfigureDutyRate };
