import { DatePicker, DefaultButton, Dialog, DialogFooter, Dropdown, mergeStyleSets, PrimaryButton, Stack, TextField } from 'office-ui-fabric-react';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { DayPickerStrings } from '../DatePickerConfigs';
import { InputType } from '../generic-form/GenericFormConfig';
var ColumnUpdateDialog = function (props) {
    var controlClass = mergeStyleSets({
        textFieldClass: {
            display: 'block',
            margin: 10,
            width: '90%'
        },
        datePickerClass: {
            display: 'block',
            margin: 10,
            width: '90%'
        }
    });
    var textFieldStyles = { fieldGroup: {} };
    var dialogContentProps = {
        title: 'Update Column'
    };
    var _a = useState(''), gridColumn = _a[0], setGridColumn = _a[1];
    var _b = useState({}), columnDialogValues = _b[0], setColumnDialogValues = _b[1];
    var stackTokens = { childrenGap: 10 };
    var dropdownStyles = {
        dropdown: { width: '90%', margin: 10 },
    };
    var onTextUpdate = function (ev, text) {
        var _a;
        console.log('Text Changed: ' + text);
        setColumnDialogValues((_a = {}, _a[gridColumn] = text, _a));
    };
    var _c = React.useState(React.createElement(TextField, { className: controlClass.textFieldClass, placeholder: "Value", onChange: onTextUpdate, styles: textFieldStyles })), inputFieldContent = _c[0], setInputFieldContent = _c[1];
    var onSelectDate = function (date) {
        var _a;
        console.log('Date Selected: ' + date.toDateString());
        setColumnDialogValues((_a = {}, _a[gridColumn] = date.toDateString(), _a));
    };
    var onSelectGridColumn = function (event, item) {
        console.log(item);
        setGridColumn(item.key);
    };
    var closeDialog = React.useCallback(function () {
        if (props.onDialogCancel) {
            props.onDialogCancel();
        }
        setInputFieldContent(undefined);
    }, []);
    var saveDialog = function () {
        debugger;
        if (props.onDialogSave) {
            props.onDialogSave(columnDialogValues);
        }
        setInputFieldContent(undefined);
    };
    var createDropDownOptions = function () {
        var dropdownOptions = [];
        props.columnConfigurationData.forEach(function (item, index) {
            if (item.editable == true && item.inputType != InputType.DropDown) {
                dropdownOptions.push({ key: item.key, text: item.text });
            }
        });
        return dropdownOptions;
    };
    var options = createDropDownOptions();
    useEffect(function () {
        console.log('Key: ' + columnDialogValues.columnKey + ', Value:' + columnDialogValues.columnValue);
    }, [columnDialogValues]);
    useEffect(function () {
        var _a;
        //debugger;
        setColumnDialogValues((_a = {}, _a[gridColumn] = '', _a));
        var column = props.columnConfigurationData.filter(function (x) { return x.key == gridColumn; });
        if (column.length > 0) {
            switch (column[0].inputType) {
                case InputType.TextField:
                    setInputFieldContent(React.createElement(TextField, { className: controlClass.textFieldClass, placeholder: "Value", onChange: onTextUpdate, styles: textFieldStyles }));
                    break;
                case InputType.Date:
                    setInputFieldContent(React.createElement(DatePicker, { strings: DayPickerStrings, showWeekNumbers: true, showMonthPickerAsOverlay: true, placeholder: "Select a date...", ariaLabel: "Select a date", className: controlClass.datePickerClass, onSelectDate: onSelectDate }));
                    break;
                default:
                    setInputFieldContent(React.createElement(TextField, { className: controlClass.textFieldClass, placeholder: "Value", onChange: onTextUpdate, styles: textFieldStyles }));
                    break;
            }
        }
    }, [gridColumn]);
    return (React.createElement(Dialog, { dialogContentProps: dialogContentProps, hidden: !inputFieldContent, onDismiss: closeDialog, closeButtonAriaLabel: "Close" },
        React.createElement(Stack, { verticalAlign: "start", tokens: stackTokens },
            React.createElement(Dropdown, { placeholder: "Select the Column", ariaLabel: "Select the Column", options: options, styles: dropdownStyles, onChange: onSelectGridColumn }),
            inputFieldContent),
        React.createElement(DialogFooter, null,
            React.createElement(PrimaryButton
            // eslint-disable-next-line react/jsx-no-bind
            , { 
                // eslint-disable-next-line react/jsx-no-bind
                onClick: saveDialog, text: "Save" }),
            React.createElement(DefaultButton, { onClick: closeDialog, text: "Cancel" }))));
};
export default ColumnUpdateDialog;
