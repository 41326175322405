import { InputType } from '../generic-form/GenericFormConfig';
export var EditType;
(function (EditType) {
    EditType[EditType["None"] = 0] = "None";
    EditType[EditType["ColumnEdit"] = 1] = "ColumnEdit";
    EditType[EditType["BulkEdit"] = 2] = "BulkEdit";
    EditType[EditType["RowEdit"] = 3] = "RowEdit";
    EditType[EditType["DeleteRow"] = 4] = "DeleteRow";
    EditType[EditType["AddRow"] = 5] = "AddRow";
})(EditType || (EditType = {}));
export var CalculationType;
(function (CalculationType) {
    CalculationType[CalculationType["Addition"] = 0] = "Addition";
    CalculationType[CalculationType["Subtraction"] = 1] = "Subtraction";
    CalculationType[CalculationType["Multiplication"] = 2] = "Multiplication";
    CalculationType[CalculationType["Division"] = 3] = "Division";
})(CalculationType || (CalculationType = {}));
export var ExportType;
(function (ExportType) {
    ExportType[ExportType["XLSX"] = 0] = "XLSX";
    ExportType[ExportType["CSV"] = 1] = "CSV";
})(ExportType || (ExportType = {}));
export var Operation;
(function (Operation) {
    Operation[Operation["None"] = 1] = "None";
    Operation[Operation["Add"] = 2] = "Add";
    Operation[Operation["Update"] = 3] = "Update";
    Operation[Operation["Delete"] = 4] = "Delete";
})(Operation || (Operation = {}));
export var ColumnConfigurationData = [
    {
        key: 'zshipref',
        text: 'Shipment Reference Num',
        editable: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'boE_NUM',
        text: 'Bill of Entry',
        editable: false,
        dataType: 'string',
        minWidth: 80,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'boE_DAT',
        text: 'Bill of Entry Date',
        editable: true,
        dataType: 'string',
        minWidth: 50,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'inbonD_NO',
        text: 'Internal Bond Number',
        editable: true,
        dataType: 'number',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'rewareH_DAT',
        text: 'Re-warehousing date',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'dutY_CHALLANNUM',
        text: 'Duty Challan number',
        editable: true,
        dataType: 'number',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'dutY_CHALLANDAT',
        text: 'Duty Challan date',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'dutY_PERCHAL',
        text: 'Duty as per Challan INR',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'disP_AMOUNT',
        text: 'Disputed amount',
        editable: true,
        dataType: 'number',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'disP_REAS',
        text: 'Reason for dispute',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'pD_BONDNO',
        text: 'PD Bond No',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'amT_UTL',
        text: 'Amount Utilised INR',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'pD_BONDBAL',
        text: 'PD Bond Balance INR',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'edD_CHALNO',
        text: 'EDD Challan number',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'edD_CHALDAT',
        text: 'EDD Challan date',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'eD_DEPOSIT',
        text: 'ED Deposit INR',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'arrivaL_DAT',
        text: 'Arrival Date',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'clrdaT_OOC',
        text: 'Clearance Date',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'clrdaT_PORT',
        text: 'Clearance from Port Date',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'tat',
        text: 'Turnaround Time',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'delaY_REAS',
        text: 'Reasons for Delay',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
];
export var ASNColumnConfigurationData = [
    {
        key: 'hawbNo',
        text: 'HAWB #',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        maxLength: 60,
    },
    {
        key: 'hawbDate',
        text: 'HAWB Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'shipmentStatus',
        text: 'Shipment Status',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.DropDown,
        dropdownValues: [],
    },
    {
        key: 'freightForwarder',
        text: 'Freight Forwarder',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        maxLength: 35,
    },
    {
        key: 'portOfShipment',
        text: 'Port Of Shipment',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        maxLength: 35,
    },
    {
        key: 'portEntry',
        text: 'Port Of Entry',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        maxLength: 28,
    },
    {
        key: 'estimateArrivalDate',
        text: 'Estimated Arrival Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'grossWeight',
        text: 'Gross Weight',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
    },
    {
        key: 'noPallet',
        text: 'No Pallet',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
    },
    {
        key: 'freight',
        text: 'Freight',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
    },
    {
        key: 'insurance',
        text: 'Insurance',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
    },
    {
        key: 'misc',
        text: 'Misc',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
    },
    {
        key: 'otherAdditional',
        text: 'Other Additions',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
    },
    {
        key: 'mawbNo',
        text: 'MAWB #',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
    },
    {
        key: 'maDate',
        text: 'MAWB Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'icNo',
        text: 'IC #',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        maxLength: 20,
    },
    {
        key: 'icDate',
        text: 'IC Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'pcNo',
        text: 'PC #',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        maxLength: 20,
    },
    {
        key: 'pcDate',
        text: 'PC Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'incoTerms',
        text: 'INCO Terms',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        maxLength: 10,
    },
    {
        key: 'shipType',
        text: 'Shipment Type',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        maxLength: 20,
    },
    {
        key: 'currency',
        text: 'Currency',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        //inputType: InputType.DropDown
        maxLength: 10,
    },
    {
        key: 'exchangeRate',
        text: 'Exchange Rate',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
    },
    {
        key: 'exchangeRateDate',
        text: 'Exchange Rate Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'comments',
        text: 'Comments',
        editable: true,
        dataType: 'string',
        minWidth: 300,
        maxWidth: 300,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.MultilineTextField,
        maxLength: 10000,
    },
    {
        key: 'generalDesc',
        text: 'General Description',
        editable: true,
        dataType: 'string',
        minWidth: 300,
        maxWidth: 300,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.MultilineTextField,
        maxLength: 10000,
    },
    {
        key: 'icApprovalAppliedDate',
        text: 'IC approval applied date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'icApprovalReceivedDate',
        text: 'IC approval recevied date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'pcApprovalAppliedDate',
        text: 'PC Approval Applied Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'pcApprovalReceivedDate',
        text: 'PC Approval Received Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'b17BondAmountExecuted',
        text: 'B17 Bond Amount Executed',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
    },
    {
        key: 'bgAmountAndValidity',
        text: 'BG Validity',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'b17BondOpeningBalance',
        text: 'B17 Bond Opening Balance',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
    },
    {
        key: 'invoiceReceivedDate',
        text: 'Invoice Received Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'invoiceApprovalDate',
        text: 'Invoice Approval Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'preAlertReceivedDate',
        text: 'Pre-Alert Received Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'brokerName',
        text: 'Broker Name',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
    },
    {
        key: 'coo',
        text: 'COO',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
    },
    {
        key: 'checklistReceivedDate',
        text: 'Checklist Received Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'checklistApprovalDate',
        text: 'Checklist Approval Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'customQueryReceivedDate',
        text: 'Custom Query Received Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'customQueryResolvedDate',
        text: 'Custom Query Resolved Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'dutyPaymentDate',
        text: 'Duty Payment Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
    {
        key: 'documentSharePointUploadDate',
        text: 'Document SharePoint Upload Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.Date,
    },
];
export var ASNLineItemColumnConfigurationData = [
    {
        key: 'shipmentLineItemNumber',
        text: 'Shipment Line Item #',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'poNum',
        text: 'PO #',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'poliNum',
        text: 'PO Line Item #',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'vendorAcctNo',
        text: 'Vendor Acct #',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'vendorName',
        text: 'Vendor Name',
        editable: true,
        dataType: 'string',
        minWidth: 250,
        maxWidth: 300,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
        maxLength: 50,
    },
    {
        key: 'billTo',
        text: 'Bill To',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
        inputType: InputType.MultilineTextField,
        maxLength: 10000,
    },
    {
        key: 'shipTo',
        text: 'Ship To',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
        inputType: InputType.MultilineTextField,
        maxLength: 10000,
    },
    {
        key: 'skuNumber',
        text: 'SKU Number',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
        maxLength: 18,
    },
    {
        key: 'invoiceNo',
        text: 'Invoice Number',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
        maxLength: 60,
    },
    {
        key: 'invoiceDate',
        text: 'Invoice Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
        inputType: InputType.Date,
    },
    {
        key: 'invoiceQty',
        text: 'Invoice Quantity',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'invoicePrice',
        text: 'Unit Price',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'invoiceValue',
        text: 'Total Price',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
        calculatedColumn: {
            type: CalculationType.Multiplication,
            fields: ['invoiceQty', 'invoicePrice'],
        },
    },
    {
        key: 'invoiceDescription',
        text: 'Invoice Description',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
        inputType: InputType.MultilineTextField,
        maxLength: 50,
    },
    {
        key: 'countryOfOrigin',
        text: 'Country Of Origin',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
        maxLength: 50,
    },
    {
        key: 'proformaInv',
        text: 'Proforma Invoice',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
        maxLength: 60,
    },
    {
        key: 'proformaDate',
        text: 'Proforma Invoice Date',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
        inputType: InputType.Date,
    },
    {
        key: 'hsnCode',
        text: 'HSN Code',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
        maxLength: 17,
    },
    {
        key: 'notifBasicCustomID',
        text: 'Basic Customs Duty Notification',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.DropDown,
        dropdownValues: [],
    },
    {
        key: 'notifCessID',
        text: 'CESS Notification',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.DropDown,
        dropdownValues: [],
    },
    {
        key: 'notifEcessID',
        text: 'Social Welfare Surcharge Notification',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.DropDown,
        dropdownValues: [],
    },
    {
        key: 'notifIGSTID',
        text: 'IGST Notification',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.DropDown,
        dropdownValues: [],
    },
    {
        key: 'notifSplDutyID',
        text: 'Spl Duty Notification',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        inputType: InputType.DropDown,
        dropdownValues: [],
    },
    {
        key: 'cgOpeningBalance',
        text: 'CG Opening Balance',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
];
export var ASNCalcValuesColumnConfigurationData = [
    {
        key: 'poNum',
        text: 'PO Number',
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'poliNum',
        text: 'PO LineItem No',
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'freight',
        text: 'Freight',
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'insurance',
        text: 'Insurance',
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'misc',
        text: 'Misc',
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'otherAdds',
        text: 'Other Additions',
        dataType: 'string',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'assValueINR',
        text: 'Assessable Value INR',
        dataType: 'string',
        minWidth: 250,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'basicCustomsDutyValue',
        text: 'Basic Customs Duty Value',
        dataType: 'string',
        minWidth: 250,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'socialWelfareSurchargeValue',
        text: 'Social Welfare Surcharge',
        dataType: 'number',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'cessValue',
        text: 'Cess Value',
        dataType: 'string',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'splDutyvalue',
        text: 'Spl Duty Val',
        dataType: 'string',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'igstValue',
        text: 'IGST Value',
        dataType: 'string',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'totalDutyINR',
        text: 'Total Duty INR',
        dataType: 'string',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'totalPayable',
        text: 'Total Payable',
        dataType: 'string',
        minWidth: 250,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
];
export var SummaryReportColumnConfigurationData = [
    {
        key: 'shipmentReferenceNumber',
        text: 'Shipment Reference Number',
        editable: false,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'asnDate',
        text: 'ASN Date',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'vendorName',
        text: 'Vendor Name',
        editable: true,
        dataType: 'number',
        minWidth: 300,
        maxWidth: 400,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'poNumber',
        text: 'PO Number',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'airWayBillNo',
        text: 'Air Way Bill No',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'billToLocation',
        text: 'Bill To Location',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'shipToLocation',
        text: 'Ship To Location',
        editable: true,
        dataType: 'string',
        minWidth: 250,
        maxWidth: 1000,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'proformaInvoiceNumber',
        text: 'Proforma Invoice Number',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'proformaInvoiceDate',
        text: 'Proforma Invoice Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'shipperSInvoiceNumber',
        text: "Shipper's Invoice Number",
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'shipperSInvoiceDate',
        text: "Shipper's Invoice Date",
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'icNo',
        text: 'IC No',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'icDate',
        text: 'IC Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'pcNo',
        text: 'PC No',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'pcDate',
        text: 'PC Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'nameOfFreightForwarder',
        text: 'Name of Freight Forwarder',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'portOfShipment',
        text: 'Port of Shipment',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'mawbNo',
        text: 'MAWB No',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'mawbDate',
        text: 'MAWB Date',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'hawbBL',
        text: 'HAWB/BL No',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'hawbBlDate',
        text: 'HAWB/BL Date',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'typeOfShipment',
        text: 'Type of Shipment',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'portOfEntryInIndia',
        text: 'Port of Entry in India',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'eta',
        text: 'ETA',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'grossWeightKgs',
        text: 'Gross Weight(kgs)',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'noOfPallets',
        text: 'No. of Pallets',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'invoiceValue',
        text: 'Invoice Value',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'miscellaneousCharges',
        text: 'Miscellaneous Charges',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'freightAsCalculated',
        text: 'Freight(as calculated)',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'insurance',
        text: 'Insurance',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'otherAdditions',
        text: 'Other Additions',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'exchangeRate',
        text: 'Exchange Rate',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'exchangeRateDate',
        text: 'Exchange Rate Date',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'customsValueINR',
        text: 'Customs Value INR',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'currency',
        text: 'Currency',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'basicCustomsDutyValue',
        text: 'Basic Customs Duty Value',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'igstValue',
        text: 'IGST Value',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'socialWelfareSurchargeValue',
        text: 'Social Welfare Surcharge Value',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'splDutyValue',
        text: 'Spl.Duty value',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'cessValue',
        text: 'CESS Value',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'totalCustomsDutyINR',
        text: 'Total Customs Duty INR',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'totalDutyPayable',
        text: 'Total Duty Payable',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'billOfEntryNumber',
        text: 'Bill Of Entry Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'billOfEntryDate',
        text: 'Bill of Entry Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'internalBondNumber',
        text: 'Internal Bond Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'reWarehousingDate',
        text: 'Re-Warehousing Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'dutyChallanNumber',
        text: 'Duty Challan Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'dutyChallanDate',
        text: 'Duty Challan Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'dutyAsPerChallanInr',
        text: 'Duty as per Challan INR',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'disputedAmount',
        text: 'Disputed Amount',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'reasonForDispute',
        text: 'Reason for Dispute',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'interestAmount',
        text: 'Interest Amount',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'penaltyAmount',
        text: 'Penalty Amount',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'eddChallanNumber',
        text: 'EDD Challan Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'eddChallanDate',
        text: 'EDD Challan Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'edDepositInr',
        text: 'EDD Deposit INR',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'igmArrivalDate',
        text: 'IGM/Arrival Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'customsReleaseDate',
        text: 'Customs Release Date',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'turnaroundTime',
        text: 'Turnaround Time',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'delayReas',
        text: 'Delay Reason/Remarks',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
];
export var DetailReportColumnConfigurationData = [
    {
        key: 'shipmentReferenceNumber',
        text: 'Shipment Reference Number',
        editable: false,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'shipmentLineItemNumber',
        text: 'Shipment Line Item Number',
        editable: false,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'asnDate',
        text: 'ASN Date',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'vendorCode',
        text: 'Vendor Code',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'vendorName',
        text: 'Vendor Name',
        editable: true,
        dataType: 'number',
        minWidth: 250,
        maxWidth: 1000,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'poNumber',
        text: 'PO Number',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'poItem',
        text: 'PO Item',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'airWayBillNo',
        text: 'Air Way Bill No',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'billToLocation',
        text: 'Bill To Location',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'shipToLocation',
        text: 'Ship To Location',
        editable: true,
        dataType: 'string',
        minWidth: 250,
        maxWidth: 1000,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'buyerItemNo',
        text: 'Buyer Item No',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'partNumber',
        text: 'PART Number',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'shortDescription',
        text: 'Short Description',
        editable: true,
        dataType: 'string',
        minWidth: 250,
        maxWidth: 1000,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'poQuantity',
        text: 'PO Quantity',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'poUnitPrice',
        text: 'PO Unit Price',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'poValue',
        text: 'PO Value',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'poDocumentTypeDescription',
        text: 'PO Document Type(description)',
        editable: true,
        dataType: 'string',
        minWidth: 250,
        maxWidth: 300,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'proformaInvoiceNumber',
        text: 'Proforma Invoice Number',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'proformaInvoiceDate',
        text: 'Proforma Invoice Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'shipperSInvoiceNumber',
        text: "Shipper's Invoice Number",
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'shipperSInvoiceDate',
        text: "Shipper's Invoice Date",
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'shipperInvoiceDescription',
        text: "Shipper's Invoice Description",
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'icNo',
        text: 'IC No',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'icDate',
        text: 'IC Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'pcNo',
        text: 'PC No',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'pcDate',
        text: 'PC Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'nameOfFreightForwarder',
        text: 'Name of Freight Forwarder',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'countryOfOrigin',
        text: 'Country of Origin',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'portOfShipment',
        text: 'Port of Shipment',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'mawbNo',
        text: 'MAWB No',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'mawbDate',
        text: 'MAWB Date',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'hawbBL',
        text: 'HAWB/BL No',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'hawbBlDate',
        text: 'HAWB/BL Date',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'typeOfShipment',
        text: 'Type of Shipment',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'portOfEntryInIndia',
        text: 'Port of Entry in India',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'eta',
        text: 'ETA',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'invoiceQty',
        text: 'Invoice Qty',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'invoiceUnitPrice',
        text: 'Invoice Unit Price',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'invoiceValue',
        text: 'Invoice Value',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'hsnCode',
        text: 'HSN Code',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'miscellaneousCharges',
        text: 'Miscellaneous Charges',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'freightAsCalculated',
        text: 'Freight(as calculated)',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'insurance',
        text: 'Insurance',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'otherAdditions',
        text: 'Other Additions',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'exchangeRateDate',
        text: 'Exchange Rate Date',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'exchangeRate',
        text: 'Exchange Rate',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
    },
    {
        key: 'customsValueINR',
        text: 'Customs Value INR',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'currency',
        text: 'Currency',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'basicCustomsDutyRate',
        text: 'Basic Customs Duty Rate',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'igstRate',
        text: 'IGST Rate',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'ecess',
        text: 'Social Welfare Surcharge',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'splDuty',
        text: 'Spl.Duty',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'cessRate',
        text: 'Cess Rate',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'totalDutyRate',
        text: 'Total Duty Rate',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'basicCustomsDutyValue',
        text: 'Basic Customs Duty Value',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'igstValue',
        text: 'IGST Value',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'socialWelfareSurchargeValue',
        text: 'Social Welfare Surcharge Value',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'splDutyValue',
        text: 'Spl.Duty value',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'cessValue',
        text: 'CESS Value',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'totalCustomsDutyINR',
        text: 'Total Customs Duty INR',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'totalDutyPayable',
        text: 'Total Duty Payable',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'billOfEntryNumber',
        text: 'Bill Of Entry Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'billOfEntryDate',
        text: 'Bill of Entry Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'internalBondNumber',
        text: 'Internal Bond Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'reWarehousingDate',
        text: 'Re-Warehousing Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'dutyChallanNumber',
        text: 'Duty Challan Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'dutyChallanDate',
        text: 'Duty Challan Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'dutyAsPerChallanInr',
        text: 'Duty as per Challan INR',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'eddChallanNumber',
        text: 'EDD Challan Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'eddChallanDate',
        text: 'EDD Challan Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'igmArrivalDate',
        text: 'IGM/Arrival Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'customsReleaseDate',
        text: 'Customs Release Date',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
];
